import * as React from 'react';
import { AppBar as MuiAppBar, Toolbar, CardMedia, IconButton, Container, Button, Box } from '@mui/material';
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import { config } from '@app';

function AppBar(props) {
    const { onOpenFormasPago } = props;
    const srcHref = `https://api.whatsapp.com/send?phone=${config.sendToNumber()}&text=Hola%E2%9C%8C%20%0AAparte%20numeros%3A%20%0ARifa%3A%20${config.raffle_tittle}`;
    return (
        <div>
            <MuiAppBar
                position="static"
            >
                <Container>
                    <Toolbar
                        sx={{
                            height: 125
                        }}>
                        <Box
                            sx={{ flexGrow: 1, display: { xs: "flex", sm: "none" } }}
                            color="inherit"
                        ></Box>
                        <IconButton sx={{ flexGrow: 1 }} disabled>
                            <CardMedia
                                component="img"
                                sx={{
                                    position: "absolute",
                                    top: -50,
                                    width: 150
                                }}
                                image={"/logo.png"}
                            />
                        </IconButton>
                        <Box
                            sx={{ flexGrow: 1, display: { xs: "flex", sm: "flex" } }}
                            color="inherit"
                            justifyContent="right"
                        >
                            <Button
                                sx={{ display: { xs: "none", sm: "flex" } }}
                                color="inherit"
                                onClick={onOpenFormasPago}
                            >
                                Formas de pago
                            </Button>
                            <Button
                                sx={{ display: { xs: "none", sm: "flex" } }}
                                color="inherit"
                                href={srcHref}
                            >
                                Ya pague
                            </Button>
                            <Button
                                sx={{ display: { xs: "none", sm: "flex" } }}
                                color="inherit"
                                href='mailto:contacto@wayproyect.com?subject=Contacto%20sistema%20rifas&body=Buen%20dia%2C%20estoy%20interesado%20en%20su%20sistema%20de%20rifas%2C%20me%20puede%20brindar%20mas%20informacion%3F'
                            >
                                Contacto
                            </Button>
                            <IconButton
                                sx={{
                                    display: { xs: "flex", sm: "none" },
                                }}
                                color="inherit"
                                onClick={onOpenFormasPago}
                            >
                                <AccountBalanceWalletIcon />
                            </IconButton>
                            <IconButton
                                sx={{
                                    display: { xs: "flex", sm: "none" },
                                }}
                                color="inherit"
                                href={srcHref}
                            >
                                <PriceCheckIcon />
                            </IconButton>
                            <IconButton
                                sx={{
                                    display: { xs: "flex", sm: "none" },
                                }}
                                color="inherit"
                                href='mailto:contacto@wayproyect.com?subject=Contacto%20sistema%20rifas&body=Buen%20dia%2C%20estoy%20interesado%20en%20su%20sistema%20de%20rifas%2C%20me%20puede%20brindar%20mas%20informacion%3F'
                            >
                                <ContactMailIcon />
                            </IconButton>
                        </Box>
                    </Toolbar>
                </Container>
            </MuiAppBar>
        </div >
    );
}

export default AppBar;