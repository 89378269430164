import { configureStore, createSlice } from '@reduxjs/toolkit'

const helpSlice = createSlice({
  name: 'help',
  initialState: {},
})
export const store = configureStore({
  reducer: {
    help: helpSlice.reducer
  }
})