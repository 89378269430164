import { initializeApp } from "firebase/app";
import { initializeAppCheck, ReCaptchaEnterpriseProvider } from "firebase/app-check";
// import { getAnalytics } from "firebase/analytics";
import { getFunctions } from 'firebase/functions';
import { getFirestore } from 'firebase/firestore';

if (!process.env.NODE_ENV || (process.env.NODE_ENV === 'development' && process.env.REACT_APP_APPCHEK_DEBUG_TOKEN !== undefined)) {
    window.FIREBASE_APPCHECK_DEBUG_TOKEN = process.env.REACT_APP_APPCHEK_DEBUG_TOKEN;
}

if (process.env.FIREBASE_DEBUG_TOKEN !== undefined) {
    window.FIREBASE_APPCHECK_DEBUG_TOKEN = process.env.FIREBASE_DEBUG_TOKEN;
}
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyC9TnkitUSBwyFbKue5J962KTQzFYYMmiU",
    authDomain: "martin-villegas-rifa.firebaseapp.com",
    projectId: "martin-villegas-rifa",
    storageBucket: "martin-villegas-rifa.appspot.com",
    messagingSenderId: "444605837568",
    appId: "1:444605837568:web:285489e8b91d21aa1303e5",
    measurementId: "G-4QWZ87HB97"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const appcheck = initializeAppCheck(app, {
    provider: new ReCaptchaEnterpriseProvider('6LdodEgkAAAAAIEeRheoAn8LpTqIpI2opi0Wmdmi'),
    isTokenAutoRefreshEnabled: true
});


// export const analytics = getAnalytics(app);
export const functions = getFunctions(appcheck.app);
export const firestore = getFirestore(appcheck.app);