import * as React from 'react';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, Grid, List, ListItem, ListItemIcon, ListItemText, Stack } from '@mui/material';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import { config } from '@app';
import Message from './Message';

export default function VistaPrevia(props) {
    const { onComprar, onConsultar, puedeComprar } = props;
    const packages = [
        { tickets: 1,complement: "BONO DE PRONTO PAGO 20,000 PESOS" },
        { tickets: 2,complement: "BONO DE PRONTO PAGO 20,000 PESOS" },
        { tickets: 3,complement: "BONO DE PRONTO PAGO 20,000 PESOS"},
        { tickets: 4,complement: "BONO DE PRONTO PAGO 20,000 PESOS" },
        { tickets: 5,complement: "BONO DE PRONTO PAGO 20,000 PESOS" },
        { tickets: 10,complement: "BONO DE PRONTO PAGO 100,000 PESOS" },
        { tickets: 12,complement: "BONO DE PRONTO PAGO 100,000 PESOS" },
        { tickets: 15,complement: "BONO DE PRONTO PAGO 100,000 PESOS" },
        { tickets: 20,complement: "BONO DE PRONTO PAGO 100,000 PESOS" },
        { tickets: 30,complement: "BONO DE PRONTO PAGO 100,000 PESOS" },
        { tickets: 40,complement: "BONO DE PRONTO PAGO 100,000 PESOS" },
        { tickets: 50,complement: "BONO DE PRONTO PAGO 100,000 PESOS" }
    ];

    return (
        <div>
            <Grid container spacing={2}>
                <Grid item md={7} lg={7 + 1}>
                    <CardMedia
                        component="img"
                        sx={{ maxHeight: 600 }}
                        image="/imgSorteoActual.jpg"
                        alt="Imagen del sorteo"
                    />
                </Grid>
                <Grid item md={5} lg={5 - 1}>
                    <Typography
                        component="h2"
                        variant="h5"
                        color="text.primary"
                        gutterBottom
                    >
                        Precios
                    </Typography>
                    <List
                        sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
                    >
                        {packages.map((pack, i) =>
                            <ListItem key={i}
                                sx={{ py: 0 }}
                                divider
                                dense
                            >
                                <ListItemIcon><ConfirmationNumberIcon color='primary' /></ListItemIcon>
                                <ListItemText sx={{ my: 0.25 }} primary={`${pack.tickets}\tBoleto${pack.tickets === 1 ? '' : 's'} x $ ${(config.packagePrices[pack.tickets] || (pack.tickets * config.price)).toFixed(2)} ${config.currency}`} secondary={pack.complement} />
                            </ListItem>
                        )}
                    </List>
                    {!puedeComprar && <Message />}
                    <Stack spacing={1} direction="row" justifyContent="right"
                        sx={{
                            display: {
                                sx: 'flex', md: 'none'
                            }
                        }}>
                        <Button size="large" variant='contained' color='primary' onClick={onComprar} disabled={!puedeComprar}>Comprar</Button>
                        <Button size="large" variant='outlined' onClick={onConsultar}>Consultar boleto</Button>
                    </Stack>
                </Grid>
            </Grid>

        </div>
    );
}