import React from 'react';
import { Box, List, ListItemText, Typography, ListItem, Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import { collection, addDoc, updateDoc, serverTimestamp, writeBatch, doc } from "firebase/firestore";
import { config, firestore } from '@app';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const ListItemTicket = styled("li")(({ theme }) => ({
    marginRight: theme.spacing(0.5)
}));

const useEffectOnlyOnce = (func) => React.useEffect(func, [func])

export default function Final(props) {
    const { tickets, clientData, raffleSnap, onOpenFormasPago } = props;
    const [status, setStatus] = React.useState('idle');
    const [folio, setFolio] = React.useState('0');
    const total = config.packagePrices[tickets.length] || config.price * tickets.length;
    const numbers = tickets.map(ticket => ticket.data().number);

    const reserveTickets = async () => {
        if (status === 'idle') {
            setStatus('saving');
            const batch = writeBatch(firestore);
            const selectedRef = doc(collection(raffleSnap.ref, "selected"));
            batch.set(selectedRef, {
                ...clientData,
                numbers,
                total,
                paid: false,
                timestamp: serverTimestamp(),
                lastReminder: serverTimestamp()
            });
            tickets.forEach(ticket => {
                batch.update(ticket.ref, {
                    available: false,
                    reserved: true,
                    timestamp: serverTimestamp(),
                    folio: selectedRef
                })
            });
            await batch.commit();
            setStatus('saved')
            setFolio(selectedRef.id);
        }
    }

    useEffectOnlyOnce(() => {
        reserveTickets();
    })



    return status === 'idle' || status === 'saving' ? <Typography variant="body1">Guardando...</Typography> : (
        <React.Fragment>
            <Typography variant="h5" align="center">Suerte!!!</Typography>
            <Typography align="center">Gracias por su preferencia.</Typography>
            <Typography variant="body1">Numeros</Typography>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexWrap: 'wrap',
                    listStyle: 'none',
                    p: 0
                }}
                component="ul"
            >
                {tickets.map((item) => <ListItemTicket key={item.id}><Typography variant="body2">{item.data().number}</Typography></ListItemTicket>)}
            </Box>
            <List disablePadding>
                <ListItem sx={{ p: 0 }}>
                    <ListItemText primary="Folio" />
                    <Typography variant="body2">{folio}</Typography>
                </ListItem>
                <ListItem sx={{ p: 0 }}>
                    <ListItemText primary="Total" />
                    <Typography variant="body1" sx={{ fontWeight: 700 }}>${total.toLocaleString('en-US', { minimumFractionDigits: 2 })} {config.currency}</Typography>
                </ListItem>

            </List>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexWrap: 'wrap',
                    listStyle: 'none',
                    p: 0
                }}
            >
                <Button sx={{ mt: 1 }} variant="contained" startIcon={<WhatsAppIcon />} href={`https://api.whatsapp.com/send?phone=${config.sendToNumber()}&text=Hola%E2%9C%8C%20soy%20${clientData.names}%20%20${clientData.lastNames}%0ADel%20Estado%20de%20${clientData.state}%0AAparte%20los%20numeros%3A%20%20${numbers.join(', ')}%F0%9F%8D%80%0AFolio%3A%20${folio}%0ARifa%3A%20${config.raffle_tittle}%0ATotal%3A%20%F0%9F%92%B2${total.toFixed(2)}%20${config.currency}%0AComo%20pagar%20${window.location.origin}%2F%23formas-pago`}>
                    Confirmar compra
                </Button>
                <Button sx={{ mt: 1, ml: 1 }} variant="contained" onClick={onOpenFormasPago} >
                    Como pagar
                </Button>
            </Box>
        </React.Fragment >
    )
}
