import * as React from 'react';
import { Typography, Button, Dialog, DialogTitle, DialogContent, DialogActions, List, ListItem, ListItemIcon, ListItemText, Alert } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const paymentOptions = {
    transfer: [
 {
            bank: 'BBVA',
            clabe: '',
            account: '',
            card_number: '4152 3142 4626 8851',
            destinatary: ''
        },
        {
            bank: 'BBVA',
            clabe: '',
            account: '',
            card_number: '4152 3142 4309 0662',
            destinatary: ''
        },      
        {
            bank: 'BANORTE',
            clabe: '',
            account: '',
            card_number: '4915 6630 7437 8473',
            destinatary: ''
          

        },
        {
            bank: 'CITIBANAMEX',
            clabe: '',
            account: '',
            card_number: '5204 1660 1905 1996',
            destinatary: ''            
          

            
        }
    ],
    convenience_store: [

        {
            bank: 'BANCO AZTECA',
            accounts: [
                '4027 6657 8729 5303'

            ]
        },
        {
            bank: 'BANCOPPEL',
            accounts: [
                '4169 1608 3889 0656',
                '4169 1608 6404 9508',
                '4169 1614 1685 8453',
                '4169 1614 1685 8446',
                '4169 1614 2767 5318'
            ]
        },
        {
            bank: 'SPIN',
            accounts: [
                '4217 4700 2110 8317',
                '4217 4700 3974 1174',
                '4217 4700 2461 7876',
                '4217 4700 2110 8317',
                '4217 4700 9471 8786',
                '4658 2859 1390 1835',
                '4668 2859 1390 1843',
                '4217 4700 8756 5020'

            ]
        }
    ]
}

const Bank = (props) =>
    <ListItemIcon
        sx={{
            mr: 1,
            minWidth: 120
        }}
    >
        <Typography variant="button" display="block" gutterBottom color={'primary'}>
            {props.bank}
        </Typography>
    </ListItemIcon>

export default function FormasPago(props) {
    const { open, onClose } = props;
    React.useEffect(() => {
        if (!open) {
            window.onpopstate = () => { }
            return;
        }
        window.onpopstate = function (e) {
            // console.log("history-back-listener formasPago");
            if (document.location.hash === "") {
                onClose();
            }
        }
    }, [onClose])

    React.useEffect(() => {
        if (open) document.title = "Formas de pago"
    }, [open]);

    return (<Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        maxWidth={"xs"}

    >
        <DialogTitle id="scroll-dialog-title">Formas de pago</DialogTitle>
        <DialogContent
            sx={{
                p: 0,
                m: 0
            }}>
            <Alert severity="info">Cuando haga su pago favor de confirmar al mismo numero al que reservo su compa. <br />A continuacion se muestran nuestras formas de pago.</Alert>
            {paymentOptions.transfer && paymentOptions.transfer.length > 0 &&
                <div>
                    <Alert severity="error">Pago solo por transferencia</Alert>
                    <List>
                        {paymentOptions.transfer.map((transferItem, i) =>
                            <ListItem key={i}
                                sx={{ pt: 0, pb: 0 }}
                                dense
                                divider
                            >
                                <Bank bank={transferItem.bank} />
                                <List sx={{ pt: 0, pb: 0 }}>
                                    {transferItem.clabe !== '' && <ListItemText primary={transferItem.clabe} />}
                                    {transferItem.clabe !== '' && <ListItemText secondary={"CLABE Interbancaria"} sx={{ mt: -1 }} />}
                                    {transferItem.account !== '' && <ListItemText primary={transferItem.account} />}
                                    {transferItem.account !== '' && <ListItemText secondary={"Cuenta"} sx={{ mt: -1 }} />}
                                    {transferItem.card_number !== '' && <ListItemText primary={transferItem.card_number} />}
                                    {transferItem.card_number !== '' && <ListItemText secondary={"Numero Tarjeta"} sx={{ mt: -1 }} />}
                                    <ListItemText primary={transferItem.destinatary} />
                                </List>
                            </ListItem>
                        )}
                    </List>
                </div>
            }
            {paymentOptions.convenience_store && paymentOptions.convenience_store.length > 0 &&
                <div>
                    <Alert severity="error">Pago tienda de conveniencia o transferencia</Alert>
                    <List>
                        {paymentOptions.convenience_store.map((convenience_store, i) =>
                            <ListItem key={i}
                                sx={{ pt: 0, pb: 0 }}
                                dense
                                divider
                            >
                                <Bank bank={convenience_store.bank} />
                                <List dense sx={{ pt: 0, pb: 0 }}>
                                    {convenience_store.accounts.map((item, j) =>
                                        <ListItemText key={j}
                                            primary={item}
                                        />)}
                                </List>
                            </ListItem>
                        )}
                    </List>
                </div>
            }
        </DialogContent>
        <DialogActions>
            <Button onClick={onClose}>Entendido</Button>
        </DialogActions>
    </Dialog>)
}