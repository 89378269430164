import React, { useEffect } from 'react';
import './App.css';
import { AppBar, Compra, ConsultaFolio, Detalles, FormasPago, Message, NumeroSorteo, VistaPrevia } from 'components';
import { Container, CssBaseline } from '@mui/material';
import { config } from '@app';
import { collection, onSnapshot, getDocs, query, where, limit } from "firebase/firestore";

const useEffectOnlyOnce = (func) => React.useEffect(func, [func])
let raffleSnapStatus = 'idle';
function App() {
  const [openCompras, setOpenCompras] = React.useState(false);
  const [openConsulta, setOpenConsulta] = React.useState(false);
  const [openFormasPago, setOpenFormasPago] = React.useState(false);
  const [raffleSnap, setRaffleSnap] = React.useState(undefined);
  const [startAtDS, setStartAtDS] = React.useState(undefined);
  const [puedeComprar, setPuedeComprar] = React.useState(true);

  const handleCloseCompras = () => setOpenCompras(false);
  const handleOpenFormas = () => {
    window.location.hash = "#formas-pago";
    setOpenFormasPago(true);
  }

  const handleCloseFormas = () => {
    window.location.hash = "";
    setOpenFormasPago(false);
  }
  const onComprar = () => setOpenCompras(true);
  const onConsultar = () => setOpenConsulta(true);

  useEffect(() => {
    if (window.location.hash === '#formas-pago') setOpenFormasPago(true);
  }, [openFormasPago])

  useEffectOnlyOnce(() => {
    if (raffleSnapStatus === 'idle') {
      raffleSnapStatus = 'loading';
      onSnapshot(config.raffleRef, (docSnap) => {
        const docData = docSnap.data();
        setRaffleSnap(docSnap)
        // getDocs(
        //   query(
        //     collection(docSnap.ref, "tickets"),
        //     where("available", "==", true),
        //     limit(1)
        //   )
        // ).then((ticketSnap) => {
        //   if (!ticketSnap.empty && ticketSnap.docs.length > 0) {
        //     setStartAtDS(ticketSnap.docs[0]);
        //   }
        // })
        if (raffleSnapStatus !== 'loading' && !docData.enabled) {
          alert("Se ha desactivado la compra.");
          window.location.reload(true);
        }
        setPuedeComprar(docData.enabled)
        raffleSnapStatus = 'loaded';
      });
    }
  })

  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar onOpenFormasPago={handleOpenFormas} />
      <NumeroSorteo />
      <Container maxWidth="lg">
        {raffleSnap && !puedeComprar && <Message />}
        <VistaPrevia  {...{ puedeComprar, onConsultar, onComprar }} />
        <Detalles {...{ puedeComprar, onConsultar, onComprar }} />
        {raffleSnap && openCompras && <Compra
          {...{ startAtDS, raffleSnap }}
          open={openCompras}
          onClose={handleCloseCompras}
          onOpenFormasPago={handleOpenFormas} />}
        <FormasPago open={openFormasPago} onClose={handleCloseFormas} />
        {raffleSnap && <ConsultaFolio open={openConsulta} onClose={() => setOpenConsulta(false)} raffleSnap={raffleSnap} />}
      </Container>
    </React.Fragment>
  );
}

export default App;
