import { Grid, MenuItem, TextField } from "@mui/material";
import React from "react";

const states = [
    'Aguascalientes', 'Baja California', 'Baja California Sur', 'Campeche', 'Chiapas', 'Chihuahua', 'Ciudad de México', 'Coahuila', 'Colima', 'Durango', 'Estado de México', 'Guanajuato', 'Guerrero', 'Hidalgo', 'Jalisco', 'Michoacán', 'Morelos', 'Nayarit', 'Nuevo León', 'Oaxaca', 'Puebla', 'Querétaro', 'Quintana Roo', 'San Luis Potosí', 'Sinaloa', 'Sonora', 'Tabasco', 'Tamaulipas', 'Tlaxcala', 'Veracruz', 'Yucatán', 'Zacatecas'
];

export default function Datos(props) {
    const { clientData, setClientData } = props;
    React.useEffect(() => {
        document.title = "Llenar datos"
    }, []);
    return (
        <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
                <TextField
                    required
                    id="firstName"
                    name="firstName"
                    label="Nombre(s)"
                    fullWidth
                    autoComplete="given-name"
                    variant="standard"
                    value={clientData.names}
                    onChange={e => setClientData({ ...clientData, names: e.target.value })}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    required
                    id="lastName"
                    name="lastName"
                    label="Apellido(s)"
                    fullWidth
                    autoComplete="family-name"
                    variant="standard"
                    value={clientData.lastNames}
                    onChange={e => setClientData({ ...clientData, lastNames: e.target.value })}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    required
                    id="address1"
                    name="address1"
                    label="Numero Celular"
                    fullWidth
                    type={'tel'}
                    autoComplete="shipping address-line1"
                    variant="standard"
                    value={clientData.phoneNumber}
                    onChange={e => setClientData({ ...clientData, phoneNumber: e.target.value })}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    required
                    id="outlined-select-currency"
                    label="Estado"
                    fullWidth
                    variant="standard"
                    select
                    value={clientData.state}
                    onChange={(e) => setClientData({ ...clientData, state: e.target.value })}
                >
                    {states.map((option) => (
                        <MenuItem key={option} value={option}>
                            {option}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>
        </Grid>
    )
}